@charset "UTF-8";

// Default Variables

$slick-font-path: "/fonts/8/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: $slider-dot-color;
$slick-dot-color-active: $slider-dot-color-active!default;
$slick-prev-character: '\2190' !default;
$slick-next-character: '\2192' !default;
$slick-dot-character: '\2022' !default;
$slick-dot-size: rem-calc(25);
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 1 !default;
$slick-border-radius: rem-calc(20);
$slick-padding: $slider-padding;

// Standard Slick slider styling

@function slick-image-url($url) {
  @if function-exists(images_dir) {
    @return image-url($url, false, false);
  }
  @else  {
    @return url($slick-loader-path + $url);
  }
}

@function slick-font-url($url) {
  @if function-exists(font_dir) {
    @return font-url($url);
  }
  @else  {
    @return url($slick-font-path + $url);
  }
}
.slider.variant1 .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slider.variant1 .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    .slick-loading & {
        background: #fff;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slider.variant1 .slick-slider .slick-track,
.slider.variant1 .slick-slider .slick-list{
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slider.variant1 .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slider.variant1 {

    .slick-initialized .slick-slide{
        display:block;
    }
    .slick-loading .slick-slide{
        visibility: hidden;
    }
    .slick-vertical .slick-slide{
        display: block;
        height: auto;
        border: 1px solid transparent;
    }

    .slick-slide {
        float: left;
        height: 100%;
        min-height: 1px;
            [dir="rtl"] & {
                float: right;
            }
            img {
                display: block;
            }
            &.slick-loading img {
                display: none;
            }

            display: none;

            &.dragging img {
                pointer-events: none;
            }
        }
}
@if $slick-font-family == "slick" {
  @font-face {
      font-family:"slick";
      src:    slick-font-url("slick.eot");
      src:    slick-font-url("slick.eot?#iefix") format("embedded-opentype"),
              slick-font-url("slick.woff") format("woff"),
              slick-font-url("slick.ttf") format("truetype"),
              slick-font-url("slick.svg#slick") format("svg");
      font-weight: normal;
      font-style: normal;
  }
}
.slider.variant1 .slick-prev,
.slider.variant1 .slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    margin-top: -10px;
    padding: 0;
    border: none;
    outline: none;
    &:hover, &:focus {
      outline: none;
      background: transparent;
      color: transparent;
      &:before {
        opacity: $slick-opacity-on-hover;
      }
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
}
.slider.variant1 .slick-prev:before, .slider.variant1 .slick-next:before {
    font-family: $slick-font-family;
    font-size: 20px;
    line-height: 1;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slider.variant1 .slick-prev {
    left: -25px;
    [dir="rtl"] & {
        left:  auto;
        right: -25px;
    }
    &:before {
        content: $slick-prev-character;
        [dir="rtl"] & {
            content: $slick-next-character;
        }
    }
}
.slider.variant1 .slick-next {
    right: -25px;
    [dir="rtl"] & {
        left:  -25px;
        right: auto;
    }
    &:before {
        content: $slick-next-character;
        [dir="rtl"] & {
            content: $slick-prev-character;
        }
    }
}
.slider.variant1 .slick-dots {
    position: absolute;
    bottom: -45px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    width: 100%;
    height:$slick-dot-size;

    li {
        position: relative;
        display: inline-block;
        height: $slick-dot-size;
        width: $slick-dot-size;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        button {
            border: 0;
            background: transparent;
            display: block;
            height: $slick-dot-size;
            width: $slick-dot-size;
            outline: none;
            line-height: 0;
            font-size: 0;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                &:before {
                  opacity: $slick-opacity-on-hover;
                }
            }

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                width: $slick-dot-size;
                height: $slick-dot-size;
                border-radius: $slick-border-radius;
                border:rem-calc(3) solid #FFFFFF;
                line-height: $slick-dot-size;
                text-align: center;
                background-color: $slick-dot-color;
                opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }

        }

        &.slick-active button:before {
            background-color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}
.slider.variant1 .slick-arrow.slick-hidden {
    display: none;
}

// Own slider styling
.slider.variant1 .slide {padding:$slick-padding;position:relative;background-size:cover!important;min-height:$slider-height;}
.fullWidth .slider.variant1 .slide {background-size:cover!important;}
.slider.variant1 .slide img{position:absolute;left:0;top:0;width:100%;}
.slider.variant1 .slide .caption {background:$slider-caption-background;padding:$padding-normal;color:$slider-caption-color;margin-left:$margin-large;}
.fullWidth .slider.variant1 .slide .caption {margin-left:0;}
.slider.variant1 .slide .caption h3,
.slider.variant1 .slide .caption h3 a {color:$slider-caption-color;}
.slider.variant1 .slide .caption h3:empty {display:none;}
.fullWidth .slider.variant1 .slide .caption:empty {background:none;padding:0;}
.slider.variant1 .slick-dots {position:absolute;bottom:20px;left:0;margin:0;}
.slider.variant1 .sliderImg{display:none;}