@charset "UTF-8";

.myNewsletters.variant1 label{position:relative;padding-left:30px;}
.myNewsletters.variant1 input[type=checkbox]{display:none;}
.myNewsletters.variant1 input[type=checkbox] + label:before{animation-duration: 0.5s;content:"+";float:left;line-height:0.8;transform:rotate(45deg);-ms-transform:rotate(45deg);-webkit-transform:rotate(45deg);font-weight:bold;font-size:25px;position:absolute;left:0;top:0;}
.myNewsletters.variant1 input[type=checkbox]:not(:checked) + label:before{animation-name:toCross;animation-duration: 0.5s;}
.myNewsletters.variant1 input[type=checkbox]:not(:checked) + label:hover:before{animation-name:toPlus;animation-duration: 0.5s;transform:rotate(90deg);-webkit-transform:rotate(90deg);}
.myNewsletters.variant1 input[type=checkbox]:checked + label:before{margin-top:3px;content:"";width:13px;height:9px;border-style:none none solid solid;border-width:3px;float:left;transform:rotate(-45deg); -webkit-transform:rotate(-45deg); border-color:$primary-color;}
@keyframes toPlus {
    from {transform:rotate(45deg); -webkit-transform: rotate(45deg);}
    to {transform:rotate(90deg); -webkit-transform: rotate(90deg);}
}
@keyframes toCross {
    from {transform:rotate(90deg); -webkit-transform: rotate(90deg);}
    to {transform:rotate(45deg); -webkit-transform: rotate(45deg);}
}
.myNewsletters.variant1 input.MailinglistSubscriptionsButton{@include button;}
.myNewsletters.variant1 {
    .mailinglists.optin {
        font-family:"Source Sans Pro",sans-serif;
        label {cursor:pointer;}
        ul {margin:0 0 .5rem 0;padding:0;}
        li {list-style:none;margin:0 0 .25rem 0;}
        ul ul {margin:0 0 0 1rem;padding-top:0;}
        ul li ul li {position:relative;
            &:first-child {margin:.25rem 0;}
        }
        ul ul ul li {display:none;}
        ul ul ul.open li {display:block;}
        ul li ul label + ul,
        ul li ul label + div + ul {
            display:block;
        }
        ul li ul label + ul:before,
        ul li ul label + div + ul:before {content:"+"; cursor:pointer; font-weight: 700; left:-.9rem; position:absolute; text-align: center; top:-.1rem; width:1rem;}
        ul li ul label + ul.open:before,
        ul li ul label + div + ul.open:before {content:"-";}
        > ul {padding:0 0 1rem 0;}
        .remark {display:inline-block;position:relative;
            &:before {background: #5998d3; border-radius: 50%; color: #fff; content: "i"; cursor:pointer; font-weight: 700; margin-left: .5rem; padding: 0 .5rem; position: relative; width: 2rem;}
            &:hover p {background: #ccc; display:inline-block; font-style: italic; font-size: .825rem; margin: 0 .5rem; max-width:16rem; padding: 0 1rem; position: absolute; z-index:999;}
            p {display: none; padding:0; margin:0;
                span {}
            }
        }
        input[type="submit"] {@include button;}
        input[type="submit"]:first-child {margin-right: 1rem;}
        #Form_Opt_In_Opt_Out_Buttons {overflow:hidden;}
    }
}