@charset "UTF-8";

// Default Variables

$slick-font-path: "/fonts/8/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: $slider-dot-color;
$slick-dot-color-active: $slider-dot-color-active!default;
$slick-prev-character: '\2190' !default;
$slick-next-character: '\2192' !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 1 !default;
$slick-border-radius: rem-calc(20);
$slick-padding: $slider-padding;

// Standard Slick slider styling

@function slick-image-url($url) {
  @if function-exists(images_dir) {
    @return image-url($url, false, false);
  }
  @else  {
    @return url($slick-loader-path + $url);
  }
}

@function slick-font-url($url) {
  @if function-exists(font_dir) {
    @return font-url($url);
  }
  @else  {
    @return url($slick-font-path + $url);
  }
}
.slider.variant2 .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slider.variant2 .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    .slick-loading & {
        background: #fff;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slider.variant2 .slick-slider .slick-track,
.slider.variant2 .slick-slider .slick-list{
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slider.variant2 .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slider.variant2 {

    .slick-initialized .slick-slide {
        display: block;
    }

    .slick-loading .slick-slide {
        visibility: hidden;
    }

    .slick-vertical .slick-slide {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
    .slick-slide {
        float: left;
        height: 100%;
        min-height: 1px;
            [dir="rtl"] & {
                float: right;
            }
            img {
                display: block;
            }
            &.slick-loading img {
                display: none;
            }

            display: none;

            &.dragging img {
                pointer-events: none;
            }
        }
}
@if $slick-font-family == "slick" {
  @font-face {
      font-family:"slick";
      src:    slick-font-url("slick.eot");
      src:    slick-font-url("slick.eot?#iefix") format("embedded-opentype"),
              slick-font-url("slick.woff") format("woff"),
              slick-font-url("slick.ttf") format("truetype"),
              slick-font-url("slick.svg#slick") format("svg");
      font-weight: normal;
      font-style: normal;
  }
}
.slider.variant2 .slick-prev,
.slider.variant2 .slick-next {
    position: absolute;
    display: block;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    margin-top: -20px;
    padding: 0;
    border: none;
    outline: none;
    z-index:10;
    &:hover, &:focus {
      outline: none;
      background: transparent;
      color: transparent;
      &:before {
        opacity: $slick-opacity-on-hover;
      }
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
}
.slider.variant2 .slick-prev:before, .slider.variant2 .slick-next:before {
    font-family: $slick-font-family;
    font-size: 40px;
    line-height: 1;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slider.variant2 .slick-prev {
    @include slider-prev;
    &:before {
        @include slider-prev-before;
    }
}
.slider.variant2 .slick-next {
    @include slider-next;
    &:before {
        @include slider-next-before;
    }
}

// Own slider styling
.slider.variant2 .slide {position:relative;background-size:cover!important;min-height:$slider-height;@include slider-slide-active;}
.fullWidth .slider.variant2 .slide {background-size:100% auto!important;}
.slider.variant2 .slide img{position:absolute;left:0;top:0;width:100%;}
.slider.variant2 .slide .caption {width:100%;box-sizing:border-box;position:absolute;left:0;@include slider-caption;}
.slider.variant2 .slide .caption p{margin:0;}
.fullWidth .slider.variant2 .slide .caption {margin-left:0;}
.fullWidth .slider.variant2 .slide .caption a{@include slider-caption-link;}
.slider.variant2 .slide .caption h3,
.slider.variant2 .slide .caption h3 a {@include slider-caption-title;}
.slider.variant2 .slide .caption h3:empty {display:none;}
.fullWidth .slider.variant2 .slide .caption:empty {background:none;padding:0;}
.slider.variant2 .sliderImg{display:none;}
.slideWrapper{position:relative;}

@media only screen and (max-width: 40em) {
    .slider.variant2 .slick-prev,
    .slider.variant2 .slick-next{display:none!important;}
}