@charset "UTF-8";

.pagesWithFilter.variant1{
	.filterbutton,
	.filter{
		display:none;
	}
	.item{
		overflow:hidden;
		margin:0 0 20px 0;
		padding:0 0 20px 0;
		border-bottom:1px solid $border-color;
		.btn{
			display:none;
		}
		.img{
			float:right;
			img{
				width:100px;
				margin:5px 0 0 15px;
			}
		}
		p{
			margin-bottom:0;
		}
		h4{
			margin-top:0;
		}

	}
}