@charset "UTF-8";

.myNewsletters.variant2 {
	[type="checkbox"]:not(:checked),[type="checkbox"]:checked {position: absolute; left: -9999px}
	[type="checkbox"]:not(:checked)+label,[type="checkbox"]:checked+label {position: relative; padding-left: 25px; cursor: pointer}
	[type="checkbox"]:not(:checked)+label:before,[type="checkbox"]:checked+label:before {content: ''; position: absolute; left: 0; top: 2px; width: 17px; height: 17px; border: 1px solid #aaa; background: #f8f8f8; border-radius: 3px; box-shadow: inset 0 1px 3px rgba(0,0,0,0.3) }
	[type="checkbox"]:not(:checked)+label:after,[type="checkbox"]:checked+label:after {content: '\f00c'; position: absolute; top: 1px; left: 2px; font-size: 14px; color: #09ad7e; transition: all .2s; font-family: FontAwesome}
	[type="checkbox"]:not(:checked)+label:after {opacity: 0; transform: scale(0)}
	[type="checkbox"]:checked+label:after {opacity: 1; transform: scale(1) }
	[type="checkbox"]:disabled:not(:checked)+label:before,[type="checkbox"]:disabled:checked+label:before {box-shadow: none; border-color: #bbb; background-color: #ddd}
	[type="checkbox"]:disabled:checked+label:after {color: #999 }
	[type="checkbox"]:disabled+label {color: #aaa}
	[type="checkbox"]:checked:focus+label:before,[type="checkbox"]:not(:checked):focus+label:before {border: 1px dotted $primary-color}
	label:hover:before {border: 1px solid $primary-color !important}
	#divMailinglistSubscriptionsSaveButton input {@include button;clear:left;float:left;}
	.mailinglists.optin {
		font-family:"Source Sans Pro",sans-serif;
		label {cursor:pointer;}
		ul {margin:0 0 .5rem 0;padding:0;}
		li {list-style:none;margin:0 0 .25rem 0;}
		ul ul {margin:0 0 0 1rem;padding-top:0;}
		ul li ul li {position:relative;
			&:first-child {margin:.25rem 0;}
		}
		ul ul ul li {display:none;}
		ul ul ul.open li {display:block;}
		ul li ul label + ul,
		ul li ul label + div + ul {
			display:block;
		}
		ul li ul label + ul:before,
		ul li ul label + div + ul:before {content:"+"; cursor:pointer; font-weight: 700; left:-.9rem; position:absolute; text-align: center; top:-.1rem; width:1rem;}
		ul li ul label + ul.open:before,
		ul li ul label + div + ul.open:before {content:"-";}
		> ul {padding:0 0 1rem 0;}
		.remark {display:inline-block;position:relative;
			&:before {background: #5998d3; border-radius: 50%; color: #fff; content: "i"; cursor:pointer; font-weight: 700; margin-left: .5rem; padding: 0 .5rem; position: relative; width: 2rem;}
			&:hover p {background: #ccc; display:inline-block; font-style: italic; font-size: .825rem; margin: 0 .5rem; max-width:16rem; padding: 0 1rem; position: absolute; z-index:999;}
			p {display: none; padding:0; margin:0;
				span {}
			}
		}
		input[type="submit"] {@include button;}
		input[type="submit"]:first-child {margin-right: 1rem;}
		#Form_Opt_In_Opt_Out_Buttons {overflow:hidden;}
	}
}